<template>
  <v-footer
    class="py-4"
    padless
    light
    height="auto"
    bottom
  >
      <v-row>
        <v-spacer/>
        <v-col cols="4">
          <base-btn
            v-for="(item, i) in items"
            :key="i"
            :href="item.href"
            class="ml-0 mr-3"
            color="primary"
            square
            target="_blank"
          >
            <v-icon v-text="item.icon" />
          </base-btn>
          <base-btn
            v-show="$route.name != 'home'"
            class="ml-0 mr-3"
            square
            title="Go to top"
            @click="$router.push('/')"
          >
            <v-icon>mdi-home</v-icon>
          </base-btn>
          <base-btn
            class="ml-0 mr-3"
            square
            title="Go to top"
            @click="$vuetify.goTo(0)"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </base-btn>
        </v-col>
      </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    components: {
      BaseBtn: () => import('@/components/base/Btn'),
    },

    data: () => ({
      items: [
        {
          href: 'https://www.instagram.com/spdp.uncut/',
          icon: 'mdi-instagram',
        },
        {
          href: 'https://www.facebook.com/Spdp-uncut-105480088381988',
          icon: 'mdi-facebook',
        },
      ],
    }),
  }
</script>
